import React from "react"

import "./input-text-box.scss"

const InputTextBox = props => {
  const handleChange = e => {
    props.onChange(e.target.value)
  }
  return (
    <div className={`form-group ${props.class}`}>
      <label htmlFor={`${props.name}`}>{props.label}</label>

      <input
        className="form-control"
        type={props.type}
        onChange={handleChange}
        onFocus={props.onFocus}
        pattern={props.pattern}
        value={props.value}
        disabled={props.disabled}
        placeholder={props.placeholder}
        name={props.name}
        hidden={props.hidden}
      />
    </div>
  )
}

export default InputTextBox
