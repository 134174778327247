// import axios from "axios"
import axios from "../../../constants/axios"

import endpoints from "../../../constants/endpoints"

export const PASSWORD_CHANGE = async (
  formState,
  setErrors,
  setProcessing,
  setFormState
) => {
  try {
    if (formState.new_password === formState.confirm_password) {
      const res = await axios.patch(
        process.env.GATSBY_BASE_URL + endpoints.PASSWORD_CHANGE,
        formState
      )
      if (res && res.status === 204) {
        setErrors({ success: "Password has been changed successfully." })
        setFormState({
          old_password: "",
          new_password: "",
          confirm_password: "",
        })
        setTimeout(() => {
          setErrors([])
        }, 2000)
      }
    } else {
      setErrors({ confirm_password: " Password Mismatch" })
    }
    setProcessing(false)
  } catch (error) {
    if (error && error.response && error.response.data) {
      setErrors(error.response.data)
    } else {
      setErrors({ error: "Something went wrong." })
    }
    setProcessing(false)
  }
}
