import React, { useState } from "react"

import { Row, Col } from "react-bootstrap"

import { PASSWORD_CHANGE } from "./action/change-password"

import Warning from "../../images/icons/wdn-warning.png"

import InputTextBox from "../input/input-text-box"
import "./change-password.scss"

const ChangePassword = () => {
  const [isProcessing, setProcessing] = useState(false)

  const [errors, setErrors] = useState([])

  const [formState, setFormState] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  })

  const handleOldPasswordChange = value => {
    setFormState({ ...formState, old_password: value })
  }

  const handleNewPasswordChange = value => {
    setFormState({ ...formState, new_password: value })
  }

  const handleConfirmPasswordChange = value => {
    setFormState({ ...formState, confirm_password: value })
  }

  const handlePasswordChangeFormSubmit = e => {
    e.preventDefault()
    setProcessing(true)
    PASSWORD_CHANGE(formState, setErrors, setProcessing, setFormState)
  }

  const handleInputFocus = () => [setErrors([])]

  return (
    <form
      className="change-password mt-5 mb-5-rem"
      onSubmit={handlePasswordChangeFormSubmit}
    >
      <Row>
        <Col lg="8">
          <InputTextBox
            name="old-password"
            type="password"
            class={errors.old_password && "error"}
            value={formState.old_password}
            placeholder="Old Password"
            label="Old Password"
            onFocus={handleInputFocus}
            onChange={handleOldPasswordChange}
          />
          {/* </Col> */}
          {/* <Col lg="8"> */}
          <InputTextBox
            name="new-password"
            class={errors.new_password && "error"}
            value={formState.new_password}
            placeholder="New Password"
            label="New Password"
            onFocus={handleInputFocus}
            onChange={handleNewPasswordChange}
            type="password"
          />
          {/* </Col> */}
          {/* <Col lg="8"> */}
          <InputTextBox
            type="password"
            name="confirm-password"
            class={errors.confirm_password && "error"}
            value={formState.confirm_password}
            placeholder="Confirm Password"
            label="Confirm Password"
            onFocus={handleInputFocus}
            onChange={handleConfirmPasswordChange}
          />
        </Col>
        <Col lg="8">
          {errors &&
            Object.keys(errors)
              .slice(0, 1)
              .map((key, value) => {
                return (
                  <div
                    key={value}
                    className="alert-block mb-4 d-flex align-items-center"
                  >
                    <img src={Warning} className="mr-3" alt="wdn-warning" />
                    {key + " : " + errors[key]}
                  </div>
                )
              })}
        </Col>
        <Col lg="8">
          <button
            type="submit"
            disabled={isProcessing}
            className="btn p-3 w-100 btn-custom rounded primary no-border"
          >
            {!isProcessing ? "Update" : "Processing"}
          </button>
        </Col>
      </Row>
    </form>
  )
}

export default ChangePassword
