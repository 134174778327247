import React, { useContext, useEffect } from "react"

import { GlobalAuthContext } from "../../../../context/authContext"

import { SIDEBAR_MENU_STEP } from "../../../../constants/sidebar-menu"
import { CANDIDATE_CHANGE_PASSWORD_MENU } from "../../../../constants/profile-menu"

import ChangePassword from "../../../../components/change-password/change-password"
import CandidateProfileLayout from "../../../../components/layout/candidate-profile"

const SettingsPage = ({ location }) => {
  const { state } = useContext(GlobalAuthContext)
  useEffect(() => {}, [state.isAuthenticated, state.hasProfile]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <CandidateProfileLayout
      menu={CANDIDATE_CHANGE_PASSWORD_MENU}
      sidebarMenu={SIDEBAR_MENU_STEP}
      activeSidebar="Settings"
      active="Change Password"
      title="Change Password"
      location={location}
    >
      <ChangePassword />
    </CandidateProfileLayout>
  )
}

export default SettingsPage
